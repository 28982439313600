import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { CssBaseline, GeistProvider } from "@geist-ui/react";
import firebase from "firebase/app";
import "firebase/database";
import reportWebVitals from "./reportWebVitals";

const firebaseConfig = {
    apiKey: "AIzaSyB0_3CuIBT_UK7is7MpqHjwW-d_DOYibEQ",
    authDomain: "echo-6752b.firebaseapp.com",
    databaseURL: "https://echo-6752b-default-rtdb.firebaseio.com",
    projectId: "echo-6752b",
    storageBucket: "echo-6752b.appspot.com",
    messagingSenderId: "122807080874",
    appId: "1:122807080874:web:2525989287f7f094f97dd3",
    measurementId: "G-KLWT7GX9MX"
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({ ignoreUndefinedProperties: true });

if (window.location.hostname === "localhost") {
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
  firebase.database().useEmulator("localhost", 9000);
}

firebase.firestore().enablePersistence().catch();

ReactDOM.render(
  <React.StrictMode>
    <GeistProvider>
      <CssBaseline />
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </GeistProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
